import { useCallback, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { isBrowser } from "../constants/isBrowser";

export default function usePosthogFns() {
  const posthog = usePostHog();

  const setUniqueUserID = useCallback(
    (id) => {
      if (isBrowser) {
        posthog?.identify(
          id // distinct_id, required
        );
      }
    },
    [isBrowser, posthog]
  );

  const addProperties = useCallback(
    (userProperties) => {
      if (isBrowser) {
        posthog?.people.set(userProperties);
      }
    },
    [isBrowser, posthog]
  );

  const removeUniqueUserID = useCallback(() => {
    if (isBrowser) {
      posthog?.reset();
    }
  }, [isBrowser, posthog]);

  const pageView = useCallback(
    (label, data = {}) => {
      if (isBrowser) {
        const { pathname, href } = window.location;
        const pageTitle = document.title;
        const pageUrl = new URL(href);
        const referer = pageUrl.searchParams.get("referer");
        const intent = pageUrl.searchParams.get("intent");

        const properties = {
          title: pageTitle,
          url: href,
          path: pathname,
          referer,
          intent,
          ...data,
        };

        posthog?.capture(label, properties);
      }
    },
    [isBrowser, posthog]
  );

  const customEvent = useCallback(
    (type, data = {}) => {
      if (isBrowser) {
        posthog?.capture(type, {
          location: window.location.href,
          ...data,
        });
      }
    },
    [isBrowser, posthog]
  );

  // function addProperties(properties) {
  //   customEvent("add_properties", { "$set:": properties });
  // }

  function click(elementType, label, data = {}) {
    customEvent(label, {
      actionType: "click",
      elementType: elementType,
      label: label,
      ...data,
    });
  }

  function buttonClick(label, data = {}) {
    click("button", label, data);
  }

  function linkClick(label, data = {}) {
    click("link", label, data);
  }

  function success(label, description, data = {}) {
    customEvent(label, {
      actionType: "success",
      label: label,
      description: description,
      ...data,
    });
  }

  function error(label, description, data = {}) {
    customEvent(label, {
      actionType: "error",
      label: label,
      description: description,
      ...data,
    });
  }

  return {
    pageView,
    customEvent,
    click,
    buttonClick,
    linkClick,
    success,
    error,
    setUniqueUserID,
    removeUniqueUserID,
    addProperties,
  };
}
