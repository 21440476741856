import {isProd, isLocal} from "@qoohoo/common/constants/isProd"
import { removeHttp } from "@qoohoo/common/utils/string";

// replace this with the ngrok root url while developing
// export const domain = "86fb-49-205-146-131.ngrok.io";

const apiBase = process.env.API_BASE;
export const domain = apiBase ? removeHttp(process.env.API_BASE) : '';
export const websiteDomain = process.env.WEBSITE_DOMAIN;
export const dashboardDomain = process.env.DASHBOARD_DOMAIN;
export const qoohooWebsiteDomain = process.env.QOOHOO_WEBSITE_DOMAIN;
export const qoohooCheckoutUrl = process.env.QOOHOO_CHECKOUT_URL;
export const qoohooSuperCheckoutUrl = process.env.QOOHOO_SUPER_CHECKOUT_URL;
export const qoohooSuperCheckoutDomain = process.env.QOOHOO_SUPER_CHECKOUT_DOMAIN;
export const superLinkDomain = process.env.SUPERLINK_DOMAIN;
export const analyticsDomain = process.env.ANALYTICS_DOMAIN;
export const staticPagesDomain = process.env.STATIC_PAGES_DOMAIN;
export const integrationKitDomain = process.env.INTEGRATION_KIT_DOMAIN;
export const integrationKitUrl = process.env.INTEGRATION_KIT_URL;

export const customPageDomains = ["https://thefitfoodietravels.com", "https://vimalsinghrathore.com", "https://pay.birbull.com", "https://smtsebiregistered.com", "https://kalakritikarishma.com", "https://bunchoftraders.com", "https://baazigarcricketexpert.com/"];

export const riseWebsiteDomain = isProd
  ? "https://rise.qoohoo.in"
  : "https://rise.staging.qoohoo.in";

export const CDNDomain = process.env.CDN_DOMAIN;

export const appleAppLink = "https://apps.apple.com/ai/app/qoohoo/id1554639726";

export const WHITELISTED_MOBILE_CODES = process.env.WHITELISTED_MOBILE_CODES ? process.env.WHITELISTED_MOBILE_CODES.trim().split(',').map(c => c.toString().trim()) : ['91'];
export const WHITELISTED_COUNTRIES = process.env.WHITELISTED_COUNTRIES ? process.env.WHITELISTED_COUNTRIES.trim().split(',').map(c => c.toString().trim()) : ['IN'];